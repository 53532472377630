
import { defineComponent } from "vue";

import Swal from "sweetalert2/dist/sweetalert2.min.js";
import Datatable from "@/components/kt-datatable/KTDatatable.vue";
import * as Yup from "yup";
import { VueCookieNext } from "vue-cookie-next";
import ApiService from "@/core/services/ApiService";
import { apiEndpoint } from "@/mixin/apiEndpoint";
import AddJobDrawer from "@/layout/header/partials/job-placement/AddJobDrawer.vue";
import EditJobDrawer from "@/layout/header/partials/job-placement/EditJobDrawer.vue";
import ReleaseJobDrawer from "@/layout/header/partials/job-placement/ReleaseJobDrawer.vue";

export default defineComponent({
  mixins: [apiEndpoint],
  name: "new-Job-Placement",
  components: {
    Datatable,
    AddJobDrawer,
    EditJobDrawer,
    ReleaseJobDrawer,
  },
  data() {
    return {
      batch: {
        entity_id: "",
        tranche_id: "",
        institute_id: "",
        course_id: "",
        employment_type: "",
        batch_id: "",
      },
      api_url: "",
      tranches_info: [],
      traineeList: [],
      entityInfos: [],
      tableHeader: [
        {
          name: "Actions",
          key: "actions",
          width: "130px",
        },
        {
          name: 'Sl',
          key: 'sl',
          sortable: true,
          width: '5px',
        },
        {
          name: "Photo",
          key: "image",
          sortable: true,
          width: "5px",
        },
        {
          name: "Name",
          key: "name",
          sortable: true,
        },
        {
          name: "Ref No.",
          key: "ref_no",
          sortable: true,
        },
        {
          name: "Reg No.",
          key: "reg_no",
          sortable: true,
        },
        {
          name: "Father Name",
          key: "father_name",
          sortable: true,
        },
        {
          name: "Mother Name",
          key: "mother_name",
          sortable: true,
        },
        {
          name: "NID",
          key: "nid",
          sortable: true,
        },
        {
          name: "Mobile",
          key: "phone",
          sortable: true,
        },
        {
          name: "Employment Status",
          key: "employment_status",
          sortable: true,
        },
      ],
      componentKey:0,
      loading: false,
      instituteList: [],
      courseList: [],
      batchList: [],
      courseInfoData: false,
      showDataTable: false,
    };
  },
  async created() {
    this.emitter.on("job_data_updated", async () => {
      this.getTraineeList();
    });
    await this.getTranches();
    this.api_url = this.VUE_APP_API_URL;
    await this.getEntityInfos();
  },
  methods: {
    async changeTranche(){
      this.batch.entity_id = '';
      this.batch.institute_id = '';
      this.batch.course_id = '';
      this.batch.batch_id = '';
      this.showDataTable = false;

    },
    async getEntityInfos() {
      this.loading = true;
      let entity_id = "";
      if (VueCookieNext.getCookie("_seip_entity_type") != 1004) {
        entity_id = VueCookieNext.getCookie("_entity_id");
      }
      await ApiService.get(this.VUE_APP_GET_ENTITY_LIST_API+"?entity_id="+entity_id)
        .then((response) => {
          this.loading = false;
          this.entityInfos = response.data.data;
        })
        .catch(({ response }) => {
          this.loading = false;
          console.log(response);
        });
    },
    async getTraineeList() {
      if(this.batch.batch_id){
        this.loading = true;
        await ApiService.get(
          this.VUE_APP_EMPLOYMENT_TRAINEE_LIST_API +
            "?tranche_id=" +
            this.batch.tranche_id +
            "&entity_id=" +
            this.batch.entity_id +
            "&training_institute_id=" +
            this.batch.institute_id +
            "&course_info_id=" +
            this.batch.course_id +
            "&batch_info_id=" +
            this.batch.batch_id +
            "&employment_type=" +
            this.batch.employment_type +
            "&active_status=1"
        )
          .then((response) => {
            this.loading = false;
            this.traineeList = response.data.data;
            this.showDataTable = true;
            this.componentKey += 1;
          })
          .catch((response) => {
            console.log(response);
            this.loading = false;
          });
      }
    },
    Add(data) {
      this.emitter.emit("add_job_drawer", data);
    },
    edit(data) {
      this.emitter.emit("edit_job_drawer", data);
    },
    Release(data) {
      this.emitter.emit("release_job_drawer", data);
    },
    async courseInfo() {
      this.batch.course_id = '';
      this.batch.batch_id = '';
      this.showDataTable = false;
      this.loading = true;
      await ApiService.get(
        this.VUE_APP_COURSE_SEARCH_LIST_API +
          "?entity_id=" +
          this.batch.entity_id +
          "&tranche=" +
          this.batch.tranche_id +
          "&institute_info_id=" +
          this.batch.institute_id
      )
        .then((response) => {
          this.loading = false;
          this.courseList = response.data.data;
        })
        .catch(({ response }) => {
          this.loading = false;
          console.log(response);
        });
    },
    async getTranches() {
      this.loading = true;
      await ApiService.get(this.VUE_APP_TRANCHE_LIST_API)
        .then((response) => {
          this.tranches_info = response.data.data;
          this.loading = false;
        })
        .catch(({ response }) => {
          console.log(response);
          this.loading = false;
        });
    },
    async getTrainingInstitute() {
       this.loading = true;
      this.batch.institute_id = '';
      this.batch.course_id = '';
      this.batch.batch_id = '';
      let institute_info_id = '';
      this.showDataTable = false;
       if (VueCookieNext.getCookie("_seip_entity_type") == 1011) {
        institute_info_id = VueCookieNext.getCookie("_institute_info_id");
      }
      await ApiService.get(
        this.VUE_APP_INSTITUTE_LIST_API + "?entity_id=" + this.batch.entity_id+"&institute_info_id=" + institute_info_id
      )
        .then((response) => {
          this.loading = false;
          this.instituteList = response.data.data;
        })
        .catch(({ response }) => {
          this.loading = false;
          console.log(response);
        });
    },
    async batchInfo() {
      this.loading = true;
      this.showDataTable = false;
      await ApiService.get(
        this.VUE_APP_BATCH_LIST_API +
          "?entity_id=" +
          this.batch.entity_id +
          "&course_info_id=" +
          this.batch.course_id +
          "&institute_info_id=" +
          this.batch.institute_id
      )
        .then((response) => {
          this.loading = false;
          this.batchList = response.data.data;
        })
        .catch(({ response }) => {
          this.loading = false;
          console.log(response);
        });
    },
    Delete(id) {
      Swal.fire({
        title: "Are you sure you want to delete it?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, delete!",
      }).then((result) => {
        if (result.isConfirmed) {
          this.loading = true;
          ApiService.delete("employment/delete/" + `${id}`)
            .then((response) => {
              this.loading = false;
              Swal.fire({
                title: "Deleted!",
                text: response.data.data,
                icon: "success",
                buttonsStyling: false,
                confirmButtonText: "Ok",
                customClass: {
                  confirmButton: "btn btn-success",
                },
              }).then(() => {
                this.emitter.emit("job_data_updated", true);
              });
              
            })
            .catch(({ response }) => {
              console.log(response);
            });
        }
      });
    },
  },
});
