
//import ApiService from "@/core/services/ApiService";
import { defineComponent } from "vue";
import { Field } from "vee-validate";
import Swal from "sweetalert2/dist/sweetalert2.min.js";
import * as Yup from "yup";
import { DrawerComponent } from "@/assets/ts/components/_DrawerComponent";
import ApiService from "@/core/services/ApiService";
import { apiEndpoint } from "@/mixin/apiEndpoint";

export default defineComponent({
  mixins:[apiEndpoint],
  name: "release_job_drawer",
  components: {
    Field,
  },
  data() {
    return {
      bankType: false,
      release_date:"",
      id:"",
      url: "",
      load: false,
      loading: false,
    };
  },
  async created() {
    this.load = true;
    this.emitter.on("release_job_drawer", async (data) => {
      this.id = data;
      console.log(this.id);
    });
  },
  methods: {
    changeType() {
      
    },
    async formSubmit() {
      this.loading = true;
      let data=new FormData();
      data.set("id",this.id);
      data.set("release_date",this.release_date);
      ApiService.post(this.VUE_APP_EMPLOYMENT_RELEASE_API,data)
      .then((response)=>{
          if (response.status == 200) {
            Swal.fire({
              title: "Success!",
              text: response.data.data,
              icon: "success",
              buttonsStyling: false,
              confirmButtonText: "Ok",
              customClass: {
                confirmButton: "btn btn-success",
              },
            }).then(() => {
              this.loading = false;
              DrawerComponent?.hideAll();
              this.emitter.emit("job_data_updated", true);
            });
          } else {
            this.loading = false;
            let err = "";
            for (const field of Object.keys(response.data.errors)) {
              err += response.data.errors[field][0] + "<br>";
            }
            Swal.fire({
              title: "Please check all the required field",
              html: err,
              icon: "error",
              buttonsStyling: false,
              confirmButtonText: "Close",
              customClass: {
                confirmButton: "btn btn-danger",
              },
            });
          }
      }).catch((response)=>{
        this.loading = false;
        console.log(response);
      })
    }
  },
});
