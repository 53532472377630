
//import ApiService from "@/core/services/ApiService";
import { defineComponent } from "vue";
import { Field } from "vee-validate";
import Swal from "sweetalert2/dist/sweetalert2.min.js";
import * as Yup from "yup";
import { DrawerComponent } from "@/assets/ts/components/_DrawerComponent";
import ApiService from "@/core/services/ApiService";
import { apiEndpoint } from "@/mixin/apiEndpoint";

export default defineComponent({
  mixins: [apiEndpoint],
  name: "edit_job_drawer",
  components: {
    Field,
  },
  data() {
    return {
      bankType: false,
      api_url: process.env.VUE_APP_API_URL,
      formData: {
        id: "",
        organization_name: "",
        employment_type: "",
        organization_address: "",
        organization_country: "",
        joining_date: "",
        designation: "",
        organization_type: "",
        salary: "",
        contact_person: "",
        contact_number: "",
        contact_email: "",
        supervisor_mobile: "",
        supervisor_email: "",
        supervisor_name: "",
        business_type: "",
        remarks: "",
      },
      url: "",
      countryInfos: [],
      load: false,
      loading: false,
    };
  },
  async created() {
    this.load = true;
    await this.getCountryInfos();
    this.emitter.on("edit_job_drawer", async (data) => {
      this.formData = data;
      // this.formData.company_name = data.organization_name;
      // this.formData.employment_type = data.employment_type;
      // this.formData.company_address = data.organization_address;
      // this.formData.organization_country = data.organization_country;
      // this.formData.start_date = data.joining_date;
      // this.formData.designation = data.designation;
    
      // this.formData.company_contact_person_email = data.contact_email;
      // this.formData.company_contact_person_mobile = data.contact_mobile;
      // this.formData.business_type = data.organization_type;
      // this.formData.remarks = data.remarks;
      // this.formData.salary = data.salary;
      // this.formData.supervisor_email = data.supervisor_email;
      // this.formData.supervisor_name = data.supervisor_name;
      //this.formData.supervisor_mobile = data.supervisor_number;
    });
  },
  methods: {
    async getCountryInfos() {
      await ApiService.get("configurations/country/list")
        .then((response) => {
          this.countryInfos = response.data.data;
        })
        .catch(({ response }) => {
          console.log(response);
        });
    },
    changeType() {},
    async formSubmit() {
      this.loading = true;
      let data = new FormData();
      data.set("id",this.formData.id);
      data.set("organization_name",this.formData.organization_name);
      data.set("organization_address",this.formData.organization_address);
      data.set("organization_country",this.formData.organization_country);
      data.set("joining_date",this.formData.joining_date);
      data.set("designation",this.formData.designation);
      data.set("organization_type",this.formData.organization_type);
      data.set("employment_type",this.formData.employment_type);
      data.set("salary",this.formData.salary);
      data.set("contact_person",this.formData.contact_person);
      data.set("remarks",this.formData.remarks);
      data.set("contact_email",this.formData.contact_email);
      data.set("contact_number",this.formData.contact_number);
      data.set("supervisor_name",this.formData.supervisor_name);
      data.set("supervisor_email",this.formData.supervisor_email);
      data.set("supervisor_mobile",this.formData.supervisor_mobile);
      ApiService.post(this.VUE_APP_EMPLOYMENT_EDIT_API, data)
        .then((response) => {
          if (response.status == 200) {
            Swal.fire({
              title: "Success!",
              text: response.data.data,
              icon: "success",
              buttonsStyling: false,
              confirmButtonText: "Ok",
              customClass: {
                confirmButton: "btn btn-success",
              },
            }).then(() => {
              this.loading = false;
              DrawerComponent?.hideAll();
              this.emitter.emit("job_data_updated", true);
            });
          } else {
             this.loading = false;
            let err = "";
            for (const field of Object.keys(response.data.errors)) {
              err += response.data.errors[field][0] + "<br>";
            }
            Swal.fire({
              title: "Please check all the required field",
              html: err,
              icon: "error",
              buttonsStyling: false,
              confirmButtonText: "Close",
              customClass: {
                confirmButton: "btn btn-danger",
              },
            });
          }
        })
        .catch((response) => {
           this.loading = false;
          console.log(response);
        });
    },
  },
});
