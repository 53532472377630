
//import ApiService from "@/core/services/ApiService";
import { defineComponent } from "vue";
import { Field } from "vee-validate";
import Swal from "sweetalert2/dist/sweetalert2.min.js";
import * as Yup from "yup";
import { DrawerComponent } from "@/assets/ts/components/_DrawerComponent";
import ApiService from "@/core/services/ApiService";
import { apiEndpoint } from "@/mixin/apiEndpoint";

export default defineComponent({
  mixins: [apiEndpoint],
  name: "add_job_drawer",
  components: {
    Field,
  },
  data() {
    return {
      bankType: false,
      formData: {},
      company_name: "",
      employment_type: "",
      company_address: "",
      organization_country: "",
      start_date: "",
      designation: "",
      salary: "",
      company_contact_person_name: "",
      company_contact_person_email: "",
      company_contact_person_mobile: "",
      supervisor_mobile: "",
      supervisor_email: "",
      supervisor_name: "",
      business_type: "",
      remarks: "",
      countryInfos: [],
      url: "",
      load: false,
      loading: false,
    };
  },
  async created() {
    this.load = true;
    await this.getCountryInfos();
    this.emitter.on("add_job_drawer", async (data) => {
      this.formData = data;
      // this.company_name = "";
      // this.employment_type= "";
      // this.company_address= "";
      // this.start_date= "";
      // this.designation= "";
      // this.salary= "";
      // this.company_contact_person_name= "";
      // this.company_contact_person_email= "";
      // this.company_contact_person_mobile= "";
      // this.supervisor_mobile= "";
      // this.supervisor_email= "";
      // this.supervisor_name= "";
      // this.business_type= "";
      // this.remarks= "";
      // this.url= "";
      
    });
  },
  methods: {
    async getCountryInfos() {
      await ApiService.get("configurations/country/list")
        .then((response) => {
          this.countryInfos = response.data.data;
        })
        .catch(({ response }) => {
          console.log(response);
        });
    },
    changeType() {},
    async formSubmit() {
      this.loading = true;
      let data = new FormData();
      for (var key in this.formData) {
        data.set(key, this.formData[key]);
      }
      data.set("company_name", this.company_name);
      data.set("company_address", this.company_address);
      data.set("organization_country", this.organization_country);
      data.set("start_date", this.start_date);
      data.set("designation", this.designation);
      data.set("business_type", this.business_type);
      data.set("employment_type", this.employment_type);
      data.set("salary", this.salary);
      data.set("contact_person_name", this.company_contact_person_name);
      data.set("remarks", this.remarks);
      data.set("contact_person_email", this.company_contact_person_email);
      data.set("contact_person_mobile", this.company_contact_person_mobile);
      data.set("supervisor_name", this.supervisor_name);
      data.set("supervisor_email", this.supervisor_email);
      data.set("supervisor_mobile", this.supervisor_mobile);
      ApiService.post(this.VUE_APP_EMPLOYMENT_SAVE_API, data)
        .then((response) => {
          if (response.status == 200) {
            Swal.fire({
              title: "Success!",
              text: response.data.data,
              icon: "success",
              buttonsStyling: false,
              confirmButtonText: "Ok",
              customClass: {
                confirmButton: "btn btn-success",
              },
            }).then(() => {
              this.loading = false;
              DrawerComponent?.hideAll();
              this.emitter.emit("job_data_updated", true);
            });
          } else {
            this.loading = false;
            let err = "";
            for (const field of Object.keys(response.data.errors)) {
              err += response.data.errors[field][0] + "<br>";
            }
            Swal.fire({
              title: "Please check all the required field",
              html: err,
              icon: "error",
              buttonsStyling: false,
              confirmButtonText: "Close",
              customClass: {
                confirmButton: "btn btn-danger",
              },
            });
          }
        })
        .catch((response) => {
          this.loading = false;
          console.log(response);
        });
    },
  },
});
